<template>
  <div class="container">
    <h5>{{ $t("cart.cart_title") }}</h5>
    <div class="cartList" v-if="carArr[0].length || carArr[1].length">
      <div v-for="(cate, jnx) in carArr" :key="jnx">
        <div v-show="allShow ? true : index < 5" class="cItem" v-for="(item, index) in cate" :key="index">
          <div class="checkbox">
            <q-checkbox v-if="item.stock != 0 && item.isuppershelf == 1 && item.isoperation != 0" size="sm"
              v-model="item.isSelect" />
          </div>
          <template v-if="item.sprice == '0.00' && item.stock == 0">
            <div class="cimg">
              <q-img :ratio="6 / 8" spinner-color="white" :src="item.photo" @click="$q.notify($t('common.no_goods'))" />
            </div>
          </template>
          <template v-else>
            <div @click="goodsToUrl(item)" class="cimg">
              <q-img :ratio="6 / 8" spinner-color="white" :src="item.photo" />
            </div>
          </template>
          <div class="ctxt">
            <p class="brand blod">{{ item.generalattributename }}</p>
            <p class="name">
              {{ $i18n.locale == "en" ? item.titleen : item.title }}
            </p>
            <p class="code">{{ item.code }}</p>
            <p>
              {{ $t("cart.goods_color") }}
              <span class="blod">{{
                $i18n.locale == "en" ? item.coloren : item.color
              }}</span>
            </p>
            <p v-if="item.size != '01'">
              {{ $t("cart.goods_size") }}
              <span class="blod">{{ item.size }}</span>
            </p>
            <p v-if="item.sprice != '0.00'">
              {{ $t("cart.goods_price") }}
              <span class="blod line-through" v-if="item.isdiscount == 1">{{ item.currency }}{{ item.oprice }}</span>
              <span class="blod">{{ item.currency }}{{ item.sprice }}</span>
            </p>
          </div>
          <div class="num" v-if="item.stock != 0 && item.isuppershelf == 1 && item.isoperation != 0">
            <q-btn :loading="item.btnLoading" dense color="primary" icon="add" @click="add(item, 1)" />
            <q-input maxlength="2" outlined dense square filled v-model="item.quantity" style="width: 50px"
              @focus="item.backupNum = item.quantity" @blur="numBlur(item)" />
            <q-btn :loading="item.btnLoading" dense color="primary" icon="remove" @click="reduce(item, 1)" />
          </div>
          <div v-else-if="item.isuppershelf == 0" class="error">
            {{ $t("cart.withdrawn") }}
            <span class="delet" @click="delet(item, index, cate)">{{
              $t("cart.remove")
            }}</span>
          </div>
          <div v-else-if="item.isoperation == 0" class="error">
            <span class="delet" @click="switchSite(item.channel)">{{ $t('cart.Switch_Sites') }}</span>
            <span class="delet" @click="delet(item, index, cate)">{{ $t("cart.remove") }}</span>
          </div>
          <div v-else class="error">
            {{ $t("cart.no_stock") }}
            <span class="delet" @click="delet(item, index, cate)">{{
              $t("cart.remove")
            }}</span>
          </div>
        </div>
      </div>
      <div class="look-all" v-if="carArr[0].length > 5" @click="allShow = !allShow">
        {{ allShow ? $t("cart.hide_all") : $t("cart.show_all") }}
      </div>
      <div class="statistics">
        <div style="align-items: center">
          <q-checkbox size="sm" :label="$t('cart.select')" v-model="isAllSelect" />
          <span>
            <q-btn size="sm" color="primary" :label="$t('cart.remove')" @click="SelectDelect()" />
          </span>
        </div>
        <div>
          <span>{{ $t("cart.sum") }}</span>
          <span class="blod">{{ totalInfo.num }}{{ $t("cart.num") }}</span>
        </div>
        <div>
          <span>{{ $t("cart.total") }}</span>
          <div class="smallDiv">
            <span class="blod">{{ currency }}{{ totalInfo.price }}</span>
            <p class="small">
              ({{ $t("cart.get") }}
              <span class="blod">{{ totalInfo.price * integralScale }}</span>
              {{ $t("cart.point") }})
            </p>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <div>
          <q-btn size="lg" class="full-width" color="primary" :label="$t('cart.buy')" @click="goChichek()" />
        </div>
      </div>
    </div>
    <m-empty v-if="carArr[0].length == 0 && carArr[1].length == 0" :title="$t('cart.no_goods')"></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { cartApi } from '@/api/goods'
import { goodsToUrl } from '@/utils/goodsToUrl'
import store from '@/store'

export default {
  components: {
    mEmpty
  },
  data() {
    return {
      carArr: [[], []],
      isAllSelect: false,
      totalInfo: {
        price: 0,
        num: 0
      },
      allShow: false,
      integralScale: 1
    }
  },
  computed: {
    currency() {
      return store.state.global.currency
    }
  },
  watch: {
    isAllSelect() {
      this.carArr[0].forEach((item) => {
        item.isSelect = this.isAllSelect
      })
    },
    carArr: {
      handler() {
        let totalPrice = 0
        let totalNum = 0
        if (!this.carArr[0]) {
          return
        }
        let isselectNum = 0
        this.carArr[0].forEach((item) => {
          if (item.isSelect) {
            totalPrice += parseInt(item.sprice) * parseInt(item.quantity)
            totalNum += parseInt(item.quantity)
            isselectNum++
          }
        })
        if (isselectNum === this.carArr[0].length && isselectNum !== 0) {
          this.isAllSelect = true
        } else {
          this.isAllSelect = false
        }
        this.totalInfo.price = totalPrice
        this.totalInfo.num = totalNum
      },
      deep: true
    }
  },
  methods: {
    switchSite(channel) {
      if (channel == 3) {
        window.location.href = '/uk/cart'
      } else {
        window.location.href = '/cart'
      }
    },
    async getCart() {
      const res = await cartApi({
        type: 0
      })
      if (res.status === 1) {
        this.integralScale = res.integral
        const arr = res.list
        for (const i in arr) {
          arr[i].isSelect = false
          // 库存为零或者已下架的商品排到最后面
          if (arr[i].stock === 0 || arr[i].isuppershelf === 0 || arr[i].isoperation === 0) {
            this.carArr[1].push(arr[i])
          } else {
            this.carArr[0].push(arr[i])
          }
        }
      }
    },
    // 添加购物车数量
    async add(item, num) {
      item.btnLoading = true
      const res = await cartApi({
        type: 1,
        quantity: num,
        mark: item.cguid,
        sguid: item.sguid,
        spguid: item.spguid,
        userguid: this.$store.state.saleGuid || ''
      })
      if (res.status === 1) {
        item.quantity = res.quantity
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      item.btnLoading = false
    },
    // 减少购物车数量
    async reduce(item, num) {
      item.btnLoading = true
      const res = await cartApi({
        type: 2,
        quantity: num,
        mark: item.cguid,
        sguid: item.sguid,
        spguid: item.spguid,
        userguid: this.$store.state.saleGuid || ''
      })
      if (res.status === 1) {
        item.quantity = res.quantity
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      item.btnLoading = false
    },
    // 选中删除
    SelectDelect() {
      const arr = []
      this.carArr[0].forEach((item) => {
        if (item.isSelect) {
          arr.push(item)
        }
      })
      if (!arr.length) {
        this.$q.notify(this.$t('cart.no_select'))
        return
      }
      this.$q
        .dialog({
          message: this.$t('cart.c_delete'),
          cancel: true
        })
        .onOk(async () => {
          const arr = []
          const ai = []
          for (const i in this.carArr[0]) {
            if (this.carArr[0][i].isSelect) {
              arr.push(this.carArr[0][i].spguid)
              ai.push(i)
            }
          }
          const res = await cartApi({
            type: 3,
            mark: arr.join(',')
          })
          if (res.status === 1) {
            ai.reverse()
            ai.forEach((i) => {
              this.carArr[0].splice(i, 1)
            })
          }
        })
    },
    // 购买
    goChichek() {
      const arr = []
      this.carArr[0].forEach((item) => {
        if (item.isSelect) {
          arr.push(item)
        }
      })
      if (!arr.length) {
        this.$q.notify(this.$t('cart.no_select'))
        return
      }
      // 设置订单信息
      this.$store.commit('setChichekInfo', JSON.stringify(arr))
      this.$router.push({
        path: '/chichek',
        query: {
          integralScale: this.integralScale
        }
      })
    },
    // 点击删除
    delet(item, index, arr) {
      this.$q
        .dialog({
          message: this.$t('cart.c_delete'),
          cancel: true
        })
        .onOk(async () => {
          const res = await cartApi({
            type: 3,
            mark: item.spguid
          })
          if (res.status === 1) {
            arr.splice(index, 1)
          }
        })
    },
    // 手动修改数量
    numBlur(item) {
      const num = Math.abs(parseInt(item.quantity))
      if (Number.isNaN(num)) {
        item.quantity = '1'
        return
      }
      if (num > item.stock) {
        item.quantity = item.stock
      } else {
        item.quantity = num
      }
      if (item.quantity === item.backupNum) {
        return
      }
      if (item.quantity > item.backupNum) {
        this.add(item, item.quantity - item.backupNum)
      } else {
        this.reduce(item, item.backupNum - item.quantity)
      }
    }
  },
  mounted() {
    this.getCart()
  },
  setup() {
    return {
      goodsToUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  h5 {
    text-align: center;
  }
}

.cartList {
  max-width: 978px;
  margin: 0 auto;
  padding: 0 10px;

  .cItem {
    border-bottom: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    padding: 10px 0;
    position: relative;

    .checkbox {
      width: 35px;
    }

    .cimg {
      margin: 0 20px;
      width: 120px;
      min-width: 120px;
      cursor: pointer;
    }

    .ctxt {
      p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      .code {
        margin-bottom: 30px;
      }

      .brand {
        font-size: 18px;
      }
    }

    .num {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: flex;

      :deep(.q-field__native),
      .q-field__input {
        text-align: center;
      }
    }

    .error {
      position: absolute;
      right: 0;
      bottom: 10px;
      color: #e60303;

      .delet {
        color: #333;
        text-decoration: underline;
        cursor: pointer;
        padding-left: 5px;
      }
    }
  }

  .look-all {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin: 10px 0;
  }

  .statistics {
    margin-top: 20px;

    &>div {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    .smallDiv {
      text-align: right;

      .small {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}

.btnBox {
  text-align: center;
  width: 300px;
  margin: 30px auto;
}
</style>
